// App main
const main = async() => {
    // Async load the vue module
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');
    const VueCookie = await import('vue-cookie');

    Vue.use(VueCookie);

    const alerts = Array.from(document.querySelectorAll('.alert'));

    alerts.forEach((el, i) => new Vue({
            el: el,
            components: {
            },
            data: function() {
                return {
                    ignoredIds: JSON.parse(this.$cookie.get('ignore-alerts')) || [],
                };
            },
            mounted() {
                if (this.ignoredIds && this.ignoredIds.includes(parseInt(this.$el.id.match(/\d+/g)[0])) === true) {
                    this.$el.remove()
                }
            },
            methods: {
                close: function (target, id) {
                    if (!this.ignoredIds || this.ignoredIds.includes(id) === false) {
                        this.ignoredIds.push(id);
                    }

                    // Set the cookie
                    this.$cookie.set('ignore-alerts', JSON.stringify(this.ignoredIds), 7);

                    // Remove alert from DOM and alerts collection
                    target.remove();
                    alerts.splice(i, 1);
                }
            },
        }));
};

// Execute async function
main().then(() => {
    console.log();
});

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR")
    });
}